<template>
  <div class="me-container">
    <div class="page-outter">
      <div align="center">
        <img
          class="me-title"
          v-lazy="require('../../../assets/images/title15.png')"
        />
        <div class="me-dd">
          <div
            class="me-dd-item"
            v-for="(item,index) in tds"
            :key="index"
          >
            <div class="cril">
              <img v-lazy="item.icon" />
            </div>
            <span class="s1">{{item.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "multiEnd-ch",
  data() {
    return {
      tds: [
        {
          icon: require("../../../assets/images/app-icon38.png"),
          name: "pc",
        },
        {
          icon: require("../../../assets/images/app-icon39.png"),
          name: "小程序",
        },
        {
          icon: require("../../../assets/images/app-icon40.png"),
          name: "App",
        },
        {
          icon: require("../../../assets/images/app-icon41.png"),
          name: "智能设备",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.me-container {
  width: 100%;
  background: #fff;
}
.me-title {
  width: 5rem;
  display: block;
  margin-top: 2.4rem;
}
.me-dd {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2.4rem;
}
.me-dd-item {
  .cril {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #aaaaaa;
    img {
      width: 1.4rem;
    }
  }
  .s1 {
    font-size: 0.36rem;
    color: #6aa5ff;
  }
}
</style>